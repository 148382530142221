@import '../abstracts/variables.scss';

.divider-top {
  padding-top: 1rem;
  margin-top: 1rem;
  border-top: 1px solid $om-gray400;
}

.divider-bottom {
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid $om-gray300;
}
