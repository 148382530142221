/* Colori Eagle */

$om-primary100: var(--om-primary100);
$om-primary200: var(--om-primary200);
$om-primary300: var(--om-primary300);
$om-primary400: var(--om-primary400);
$om-primary500: var(--om-primary500);
$om-primary600: var(--om-primary600);
$om-primary700: var(--om-primary700);
$om-primary800: var(--om-primary800);
$om-primary900: var(--om-primary900);

$om-gray100: var(--om-gray100);
$om-gray200: var(--om-gray200);
$om-gray300: var(--om-gray300);
$om-gray400: var(--om-gray400);
$om-gray500: var(--om-gray500);
$om-gray600: var(--om-gray600);
$om-gray700: var(--om-gray700);
$om-gray800: var(--om-gray800);
$om-gray900: var(--om-gray900);

$om-danger: var(--om-danger);
;
$om-danger-dark: var(--om-danger-dark);

$om-warning: var(--om-warning);
$om-warning-dark: var(--om-warning-dark);

$om-alert: var(--om-alert);
$om-alert-dark: var(--om-alert-dark);

$om-success: var(--om-success);
$om-success-dark: var(--om-success-dark);

$om-white: var(--om-white);
$om-black: var(--om-black);

$om-white-transparent: var(--om-white-transparent);

/* Colori Tailwind */

// $om-gray100: #f7fafc;
// $om-gray200: #edf2f7;
// $om-gray300: #e2e8f0;
// $om-gray400: #cbd5e0;
// $om-gray500: #a0aec0;
// $om-gray600: #718096;
// $om-gray700: #4a5568;
// $om-gray800: #2d3748;
// $om-gray900: #1a202c;

$textDarkest: $om-gray900;
$textDark: $om-gray800;
$textMedium: $om-gray600;
$textLight: $om-gray500;
$textLink: $om-primary600;
$textLogo: #DEEBFF;

$backgroundDarkPrimary: $om-primary600;
$backgroundMedium: $om-gray300;
$backgroundLight: $om-gray200;
$backgroundLightest: $om-gray100;
$backgroundLightPrimary: #C0EFEF;
$backgroundLightSuccess: #CEFFEC;

$borderLightest: $om-gray300;
$borderLight: $om-gray400;
$borderInputFocus: $om-primary400;

/* Colori random ancora sparsi per l'app, dovranno essere sostituiti con i colori standard */

$om-border: 1px solid $om-gray400;
$om-color: #3182CE;
$light-orange: #f7cb08;
$primary-400: #028DAD;
$dark-bg: #999;
$light-bg: #ddd;
$project-menu-color: #F4F5F7;
$left-navbar: #E2E8F0;
$gisfo-color: #fd7d21;
$box-shadow: 5px 5px 10px #00000014;
$light-grey: #f4f5f7;
$white07: hsla(0, 0%, 100%, 0.7);
$transparent-bg: rgba(0, 0, 0, 0.4);
$om-disabled: #fbfbfb;
$om-disabled-color: rgba(0, 0, 0, 0.5);
