.editor-size-select {
  width: 100px;
}

.toolbar {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.buttons-vertical {
  padding: 2px;
  display: flex;
  align-items: center;
  gap: 2px;
}

.buttons-vertical-top {
  padding: 2px;
  display: flex;
  align-items: flex-start;
  gap: 2px;
}

.buttons-horizontal {
  padding: 2px;
  display: flex;
  align-items: flex-start;
  gap: 2px;
}

.buttons-inline {
  padding: 2px;
  display: flex;
  align-items: flex-start;
  gap: 2px;
}

.mr-8 {
  margin-right: 8px;
}

.fg {
  display: flex;
  flex-direction: row;
  align-items: start;
}

[class*='fi-'] {
  padding-left: 0px;
  padding-right: 14px;
  padding-bottom: 4px;
}

formly-field-nz-checkbox {
  margin-top: 36px;
}

formly-field-nz-input {
  nz-input-number {
    width: 100% !important;
  }
}

.fi-xsmall {
  flex: 1;
}

.fi-small {
  flex: 2;
}

.fi-normal {
  flex: 3;
}

.fi-large {
  flex: 4;
}

.fi-xlarge {
  flex: 5;
}

.fi-xxlarge {
  flex: 6;
}

.fi-xxlarge {
  flex: 6;
}

.fi-full-textarea textarea {
  min-height: 300px;
  background-color: #333;
  color: lightgreen;
  font-family: 'Courier New', Courier, monospace;
  font-size: small;
}

.fi-title {
  background-color: #ededed;
  padding: 4px;
  border-radius: 6px;
  font-size: medium;
  font-weight: 600;
  margin-bottom: 8px;
}

.fi-hide {
  border: 2px solid orange;
}

.fi-header * {
  border: 0px;
  font-weight: bold;
}

.form-section-header {
  background-color: #eee;
  padding: 1px 8px;
  margin: 2px 15px 10px 0px;
  border-radius: 4px;
  color: #444;
  font-weight: bold;
  font-size: 10pt;
}

.icon-24 {
  font-size: 24px;
}

.debug {
  border: 1px solid blue;
  background-color: rgb(0, 115, 255);
}
