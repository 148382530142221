@import 'tailwindcss/base';

@import 'tailwindcss/components';

@import 'tailwindcss/utilities';

@import './sass-7-1/main.scss';

//@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;500;600;700&family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap');

* {
  font-family: "Arial", sans-serif;
}

// @font-face {
//   font-family: 'Inter'; //valore da chiamare nel CSS
//   font-style: normal;
//   font-weight: 100;
//   src: url('./assets/fonts/Inter/Inter-Thin.ttf');
// }
// @font-face {
//   font-family: 'Inter'; //valore da chiamare nel CSS
//   font-style: normal;
//   font-weight: 200;
//   src: url('./assets/fonts/Inter/Inter-ExtraLight.ttf');
// }
// @font-face {
//   font-family: 'Inter'; //valore da chiamare nel CSS
//   font-style: normal;
//   font-weight: 300;
//   src: url('./assets/fonts/Inter/Inter-Light.ttf');
// }
// @font-face {
//   font-family: 'Inter'; //valore da chiamare nel CSS
//   font-style: normal;
//   font-weight: 400;
//   src: url('./assets/fonts/Inter/Inter-Regular.ttf');
// }
// @font-face {
//   font-family: 'Inter'; //valore da chiamare nel CSS
//   font-style: normal;
//   font-weight: 500;
//   src: url('./assets/fonts/Inter/Inter-Medium.ttf');
// }
// @font-face {
//   font-family: 'Inter'; //valore da chiamare nel CSS
//   font-style: normal;
//   font-weight: 600;
//   src: url('./assets/fonts/Inter/Inter-SemiBold.ttf');
// }
// @font-face {
//   font-family: 'Inter'; //valore da chiamare nel CSS
//   font-style: normal;
//   font-weight: 700;
//   src: url('./assets/fonts/Inter/Inter-Bold.ttf');
// }
// @font-face {
//   font-family: 'Inter'; //valore da chiamare nel CSS
//   font-style: normal;
//   font-weight: 800;
//   src: url('./assets/fonts/Inter/Inter-ExtraBold.ttf');
// }
// @font-face {
//   font-family: 'Inter'; //valore da chiamare nel CSS
//   font-style: normal;
//   font-weight: 900;
//   src: url('./assets/fonts/Inter/Inter-Black.ttf');
// }


// @font-face {
//   font-family: 'Roboto'; //valore da chiamare nel CSS
//   font-style: normal;
//   font-weight: 100;
//   src: url('./assets/fonts/Roboto/Roboto-Thin.ttf');
// }
// // @font-face {
// //   font-family: 'Roboto'; //valore da chiamare nel CSS
// //   font-style: normal;
// //   font-weight: 200;
// //   src: url('./assets/fonts/Roboto/Roboto-ExtraLight.ttf');
// // }
// @font-face {
//   font-family: 'Roboto'; //valore da chiamare nel CSS
//   font-style: normal;
//   font-weight: 300;
//   src: url('./assets/fonts/Roboto/Roboto-Light.ttf');
// }
// @font-face {
//   font-family: 'Roboto'; //valore da chiamare nel CSS
//   font-style: normal;
//   font-weight: 400;
//   src: url('./assets/fonts/Roboto/Roboto-Regular.ttf');
// }
// @font-face {
//   font-family: 'Roboto'; //valore da chiamare nel CSS
//   font-style: normal;
//   font-weight: 500;
//   src: url('./assets/fonts/Roboto/Roboto-Medium.ttf');
// }
// // @font-face {
// //   font-family: 'Roboto'; //valore da chiamare nel CSS
// //   font-style: normal;
// //   font-weight: 600;
// //   src: url('./assets/fonts/Roboto/Roboto-SemiBold.ttf');
// // }
// @font-face {
//   font-family: 'Roboto'; //valore da chiamare nel CSS
//   font-style: normal;
//   font-weight: 700;
//   src: url('./assets/fonts/Roboto/Roboto-Bold.ttf');
// }
// @font-face {
//   font-family: 'Roboto'; //valore da chiamare nel CSS
//   font-style: normal;
//   font-weight: 900;
//   src: url('./assets/fonts/Roboto/Roboto-Black.ttf');
// }

// @font-face {
//   font-family: 'SegoeUI'; //valore da chiamare nel CSS
//   font-style: normal;
//   font-weight: 200;
//   src: url('./assets/fonts/SegoeUI/SegoeUI-Light.ttf');
// }
// @font-face {
//   font-family: 'SegoeUI'; //valore da chiamare nel CSS
//   font-style: normal;
//   font-weight: 300;
//   src: url('./assets/fonts/SegoeUI/SegoeUI-SemiLight.ttf');
// }
// @font-face {
//   font-family: 'SegoeUI'; //valore da chiamare nel CSS
//   font-style: normal;
//   font-weight: 400;
//   src: url('./assets/fonts/SegoeUI/SegoeUI-Regular.ttf');
// }
// @font-face {
//   font-family: 'SegoeUI'; //valore da chiamare nel CSS
//   font-style: normal;
//   font-weight: 600;
//   src: url('./assets/fonts/SegoeUI/SegoeUI-SemiBold.ttf');
// }
// @font-face {
//   font-family: 'SegoeUI'; //valore da chiamare nel CSS
//   font-style: normal;
//   font-weight: 800;
//   src: url('./assets/fonts/SegoeUI/SegoeUI-Bold.ttf');
// }
// @font-face {
//   font-family: 'SegoeUI'; //valore da chiamare nel CSS
//   font-style: normal;
//   font-weight: 900;
//   src: url('./assets/fonts/SegoeUI/SegoeUI-Black.ttf');
// }

table {
  width: 100%;


  th {
    color: rgba(0,0,0,.54);
    font-size: 12px;
    font-weight: 500;
    height: 56px;
  }


  th[align=""],
  th:not([align]) {
    text-align: left;
  }


  td {
    font-size: 15px;
    height: 48px;
  }


  td,
  th {
    padding: 0;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: rgba(0,0,0,.12);
  }
}
