@import '../abstracts/variables.scss';

.global-form-wrapper {
  max-width: 1000px;
}

.by-gisfo {
  border-left: 20px solid $gisfo-color;
}

.by-om {
  border-left: 20px solid $om-color;
}

.clickable {
  cursor: pointer;
}
