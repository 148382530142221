@import '../abstracts/mixins.scss';
@import '../abstracts/variables.scss';

.ant-layout-header {
  padding: 0 2rem;
  background-color: #fff;
  height: unset;
}

.ant-layout-content {
  padding: 1rem 2rem;
  background-color: #fff;
}

.ant-layout-footer {
  padding: 1rem 2rem;
  background-color: #fff;
}

.ant-page-header {
  padding: 1rem 0 0;

  .ant-page-header-heading-title {
    font-size: 1.5rem;
    color: rgba(66, 82, 110, 1);
  }
}

.ant-collapse {
  background-color: transparent;
  border: unset;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .ant-collapse-item {
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    background-color: #fafafa;
  }
}

.ant-collapse-header {
  align-items: center !important;

  div:first-child {
    display: flex;
  }
}

.ant-collapse-extra {
  display: flex;
  align-items: center;
  font-size: smaller;
  gap: 0.5rem;
  color: #aaa;

  i {
    font-size: 20px;
  }
}

// .ant-typography {
//   color: rgba(66, 82, 110, 1) !important;
// }

.ant-list-item {
  &.no-border {
    border: none;
    padding: 0.5rem 0 0;
  }
}

.ant-modal-wrap {
  height: 100dvh;
  width: 100dvw;
  overflow: hidden;

  .issue-modal {
    @apply modal-fullscreen;

    .ant-modal-content {
      @apply modal-content-fullscreen;
    }
  }

  .photo-modal {
    @apply modal-fullscreen;

    .ant-modal-content {
      @apply modal-content-fullscreen;
      background: rgba(0, 0, 0, 0.4);
    }
  }

  .alert-modal {
    @apply modal-fullscreen;

    .ant-modal-content {
      max-height: 90vh;
      max-width: 90vw;
      border-radius: 6px;

      @include breakpoint(md, max) {
        width: 90vw;
      }

      @include breakpoint(md, min) {
        width: 450px;
      }

      .ant-modal-body {
        height: 100%;
      }
    }
  }
}

.modal-fullscreen {
  position: fixed;
  inset: 0;
  max-width: unset;
  margin: auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100% !important;
}

.modal-content-fullscreen {
  height: 100%;
  width: 100%;

  .ant-modal-body {
    height: 100%;
  }
}

.ant-picker-calendar-header {
  display: none !important;
}

.only-dot {
  .ant-badge-status-text {
    margin: 0;
  }
}

.ant-notification-notice-description {
  white-space: pre-line;
}

.collapse-without-padding {
  .ant-collapse-content-box {
    padding: 0 !important;
  }
}

.ant-collapse>.ant-collapse-item {
  .ant-collapse-header {
    @apply bg-gray-100 border-gray-300;
  }

  .ant-collapse-content {
    @apply border-gray-300;
  }
}

.ant-pagination {
  margin-top: 4px !important;
  margin-bottom: 0px !important;
}

.ant-input-disabled,
.ant-input[disabled] {
  background-color: $om-disabled !important;
  color: $om-disabled-color !important;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: $om-disabled !important;
  color: $om-disabled-color !important;
}
