@import '../abstracts/variables.scss';

body {
  height: 100dvh; 
  width: 100dvw; 
  box-sizing: border-box; 
  overflow: hidden; 
  touch-action: none;
}

textarea {
  -webkit-user-select: text !important;
  -khtml-user-select: text !important;
  -moz-user-select: text !important;
  -ms-user-select: text !important;
  user-select: text !important;
}

.debug {
  background-color: black;
  color: rgb(223, 126, 223);
  padding: 4px;
  border-radius: 6px;
}

.debug-lite {
  border: 1px solid blue;
  border-radius: 3px;
}


.pre-bootstrapping-container {
  @apply w-full h-full relative;

  .pre-bootstrapping-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.gray {
  color: #aaa;
}

.black {
  color: #000;
}

a {
  @apply text-blue-600;
  &:hover {
    @apply text-blue-800; 
  }
}
