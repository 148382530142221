@import 'abstracts/variables';
@import 'abstracts/mixins';

@import 'base/reset';
@import 'base/ng-zorro';
@import 'base/typography';

@import 'components/buttons';
@import 'components/forms';
@import 'components/scrollbar';
@import 'components/meta-interface';
@import 'components/interface';
@import 'components/layer-switcher.scss';

@import 'layout/layout-elements';

@layer base {
  :root {
    --om-primary100: rgb(192, 239, 239);
    --om-primary200: rgb(143, 227, 236);
    --om-primary300: rgb(69, 209, 225);
    --om-primary400: rgb(60, 192, 219);
    --om-primary500: rgb(23, 164, 207);
    --om-primary600: rgb(0, 143, 193);
    --om-primary700: rgb(0, 87, 107);
    --om-primary800: rgb(0, 45, 56);
    --om-primary900: rgb(0, 31, 36);

    --om-gray100: rgb(247, 249, 249);
    --om-gray200: rgb(232, 239, 238);
    --om-gray300: rgb(200, 218, 216);
    --om-gray400: rgb(167, 192, 191);
    --om-gray500: rgb(150, 172, 171);
    --om-gray600: rgb(92, 114, 114);
    --om-gray700: rgb(43, 57, 57);
    --om-gray800: rgb(26, 37, 38);
    --om-gray900: rgb(0, 10, 12);

    --om-danger: rgb(219, 11, 61);
    --om-danger-dark: rgb(150, 9, 43);

    --om-warning: rgb(243, 136, 29);
    --om-warning-dark: rgb(178, 89, 0);

    --om-alert: rgb(237, 237, 21);
    --om-alert-dark: rgb(182, 169, 0);

    --om-success: rgb(0, 213, 132);
    --om-success-dark: rgb(0, 135, 83);

    --om-white: rgb(252, 252, 252);
    --om-black: rgb(0, 10, 12);

    --om-white-transparent: hsla(0, 0%, 100%, 0.7);
  }
}
