@import '../abstracts/variables.scss';

.overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover {
    overflow: visible;
    white-space: normal;
    height: auto;
  }
}

.max-two-lines {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@supports (display: -webkit-box) {
  .max-two-lines {
    /* autoprefixer: off */
    display: -webkit-box !important;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden !important;
    white-space: normal !important;
  }
}

.om-text {
  &.h1 {
    font-weight: 800;
    font-size: 3.052rem;
  }
  &.h2 {
    font-weight: 800;
    font-size: 2.441rem;
  }
  &.h3 {
    font-weight: 800;
    font-size: 1.953rem;
  }
  &.h4 {
    font-weight: 800;
    font-size: 1.563rem;
  }
  &.h5 {
    font-weight: 800;
    font-size: 1.25rem;
  }
  &.h6 {
    font-weight: 800;
    font-size: 1rem;
  }
  &.sub {
    font-weight: 600;
    font-size: 1.25rem;
  }
  &.text {
    font-weight: 400;
    font-size: 1rem;
  }
  &.small {
    font-weight: 400;
    font-size: 0.8rem;
  }
  &.bold {
    font-weight: 600;
  }
  &.gray {
    color: $om-gray500;
  }
}
