.layer-switcher {
  background-color: transparent !important;
  top: 0.5em !important;
  right: 0.5em !important;
}

/* Menù */
.layer-switcher .panel {
  background-color: rgba(255, 255, 255, 0.9);
  font-size: 0.7rem;
}

/* Menù container */
.layer-switcher .panel ul:first-child {
  margin: 8px;
}

/* Menù list container */
.layer-switcher .panel ul ul {
  padding-left: 6px;
}

/* Menù list element */
.layer-switcher .panel li.layer {
  display: flex;
  align-items: center;
}

/* Bottone Menù */
.layer-switcher button {
  left: -1px !important;
  top: -1px !important;
}

/* Bottone Menù : on hover */
.layer-switcher button:focus {
  outline: none !important;
  background-color: #eee !important;
}
