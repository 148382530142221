::-webkit-scrollbar {
  width: 1rem;
  height: 1rem;
}

::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 2px;
  border-width: 1px;
  border-style: solid;
  border-color: transparent
}

::-webkit-scrollbar-thumb {
  background: #cfd6e0;
  border-radius: 2px;
  border-width: 6px;
  border-style: solid;
  border-color: transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background: #aabace ;
  border-width: 5px;
  border-style: solid;
  border-color: transparent;
  background-clip: content-box;
}

/* 
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  background: #cfd6e0;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background: #aabace;
}
 */
